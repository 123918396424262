<template>
  <div>
    <app-card>
      <template #body>
        <ticket-form :ticket="ticket" @change="form = $event"/>
      </template>
    </app-card>

    <portal to="subheader-toolbar">
      <b-button class="mr-3" variant="transparent-white" @click="$router.push({name: 'showTicket', params: {id: ticket.id}})">
        {{ $t('btn.back_to_ticket') }}
      </b-button>
      <b-button class="mr-3" variant="danger" v-b-modal.delete-ticket-modal>
        {{ $t('btn.delete') }}
      </b-button>
      <b-button variant="success" @click="updateItem">
        {{ $t('btn.update') }}
      </b-button>
    </portal>

    <app-modal
        id="delete-ticket-modal"
        :title="$t('label.confirm_action')"
        :ok-text="$t('btn.delete')"
        ok-variant="danger"
        @ok="deleteItem"
    >
      <template #app-modal-body>
        <span class="font-size-lg">{{ $t('modal.delete_ticket') }}?</span>
      </template>
    </app-modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import AppCard from '@/components/AppCard';
import AppModal from '@/components/modals/AppModal';
import TicketForm from '@/views/tickets/components/TicketForm';

export default {
  components: {
    AppCard,
    AppModal,
    TicketForm
  },
  data: () => ({
    form: {},
  }),
  beforeMount() {
    this.$store.dispatch('ticketsStore/GET_TICKET', this.$route.params.id);
  },
  computed: {
    ...mapGetters({
      ticket: 'ticketsStore/TICKET'
    })
  },
  methods: {
    updateItem() {
      this.$store.dispatch('ticketsStore/UPDATE', {id: this.ticket.id, data: this.form}).then(() => {
        this.$router.push({name: 'showTicket', params: {id: this.ticket.id}});
      });
    },
    deleteItem() {
      this.$store.dispatch('ticketsStore/DELETE', this.ticket.id).then(() => {
        this.$router.push({name: 'ticketsList'});
      });
    },
    goToCreateTask() {
      this.$router.push({name: 'createTask', params: {ticket_id: this.$route.params.id}});
    }
  }
}
</script>